import { getClientInstance } from './useClient';
import React, { useState, useEffect } from 'react';
const choicesList = new Map();
const pendingRequests = new Map();

const setChoicesList = (type, values) => {
  choicesList.set(type, values);
  pendingRequests.delete(type);
};

export const getChoice = async ({ type, value, isModel }) => {
  const user = getClientInstance();
  let values;

  if (choicesList.has(type)) {
    values = choicesList.get(type);
  } else if (pendingRequests.get(type)) {
    values = await pendingRequests.get(type);
  } else {
    const fetchPromise = user
      .get(`${isModel ? 'model' : 'form'}/choice/${type}`)
      .then((fetchedValues) => {
        setChoicesList(type, fetchedValues);
        return fetchedValues;
      });
    pendingRequests.set(type, fetchPromise);
    values = await fetchPromise;
  }

  if (value) {
    return values.find((choice) => choice.value === value) || null;
  } else {
    return values;
  }
};

export const useChoice = ({
  type,
  value,
  option = '',
  getAll = false,
  isModel = false,
}) => {
  const [choice, setChoice] = useState('');

  useEffect(() => {
    const fetchChoice = async () => {
      if (type) {
        if (value || getAll || isModel) {
          const result = await getChoice({ type, value, isModel });
          if (result) {
            if (getAll || isModel) {
              setChoice(result);
            } else {
              if (option) {
                setChoice(result[option]);
              } else {
                setChoice(result?.label);
              }
            }
          }
        }
      }
    };

    fetchChoice();
  }, [type, value]);

  return choice;
};

export default useChoice;
